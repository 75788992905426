import React from 'react';
import Layout from '../../components/layout';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import ContentCopy from '../../components/content-copy';
import ContentImageXL from '../../components/content-image-xl';
import ContentImageL from '../../components/content-image-l';
import ContentImageM from '../../components/content-image-m';


const VolantisDS = ({ data }) => (
  <Layout>
    <section className="columns is-centered case-studies is-gapless is-multiline">
      <div className="column is-12">
        <Img
          className="featured-image"
          fluid={data.imageOne.childImageSharp.fluid}
          alt="Featured Image"
        />
      </div>
      <div className="column is-12 padding-bottom-0 padding-top-0">
        <div className="columns is-centered">
          <div className="column is-5 is-offset-1">
            <div className="page-title-wrap margin-bot-3">
              <span className="title-decoratiion"></span>
              <h1 className="general-page-title">Volantis Design System</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-5 padding-top-0">
        <div className="table-wrapper">
          <table className="table is-fullwidth">
            <tbody>
              <tr>
                <td><h2>Client</h2></td>
                <td><p>Volantis Technology - AI Platform</p></td>
              </tr>
              <tr>
                <td><h2>Overview</h2></td>
                <td>
                  <p>Volantis helps empower everyone in an organization to utilize the power of AI to solve complex business problems. Automate processes and predict the business's outcomes to help improve business performance.</p><br />
                  <p>There were 2 UI/UX Designers, 9 Front-end Engineers, and 1 UI Engineer that develop and maintain some web apps.</p><br />
                  <p>After some breaking chance and frictions happened between designers and engineers everyone start to realize the needs to fix the workflow and regulate everything. Otherwise, things will get messier and slower overtime. Even worse, everyone could burn out and unmotivated.</p><br />
                  <p>It was designer's inisiative to refine the design language and create the guideline that would increase our design and development velocity, avoid reinventing the wheel, and improve the workflow between the design and engineer team.</p> <br />
                </td>
              </tr>
              <tr>
                <td><h2>Outcome</h2></td>
                <td>
                  <p>After a couple weeks testing out the new workflow, we found some interesting results:</p>
                  <ol>
                    <li>No more breaking change whenever new design applied.</li>
                    <li>No more random blaming.</li>
                    <li>Able to spot someone who messes the workflow.</li>
                    <li>Foster the sense of belonging.</li>
                    <li>Better overall collaboration between designers and engineers.</li>
                    <li>Bring everyone on the same page.</li>
                  </ol>
                  <br />
                </td>
              </tr>
              <tr>
                <td><h2>My Role</h2></td>
                <td><p>UI/UX Designer</p></td>
              </tr>
              <tr>
                <td><h2>Tools</h2></td>
                <td><p>Sketch App, Abstract, Trello.</p></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="columns is-centered padding-top-0 is-multiline">
        <div className="column is-12">
          <ContentImageXL>
            <Img
              fluid={data.imageTwo.childImageSharp.fluid}
              alt="volantis product lineup"
            />
          </ContentImageXL>
          <ContentCopy>
            <h2>Research</h2>
            <h3>The Issues</h3>
            <p>There are several designers who work on different products, frequently re-creating components that have already been made by other designers.</p><br />

            <p>Designers have difficulty making new components because they have to check all the designs that have been made (hundreds of artboard and sketch files) because there is no clear conventions or guidelines.</p><br />
            <p>There were inconsistencies in the design language used on the landing page and the product's UI (due to the product release timeline, this issue still postponed). The difference is quite significant because it includes typography, color, and spacing.</p><br />

            <p>Although the team have already used Abstract as versioning tool there is no clear standard procedure when there are additions or revisions to the design either it from the designer, engineer, or product owner. Because each work in a different product is often not communicated and documented.</p><br />

            <p>Engineers often make their own decisions to use temporary design solutions that are always not communicated to other designers and engineers.</p><br />

            <p>There are no official guidelines that can be used by designers and developers together to monitor updates and implementations in the UI Kit and production.</p><br />

            <p>Assets handoff use various channels such as Slack, Email, Google Drive, and WhatsApp. This non-uniform communication causes problems around versioning, access sharing, and is difficult to validate.</p>

            <h3>User Interview</h3>
            <p>I did one-on-one interview sessions with <strong>9 Front-end engineers, 1 UI engineer,</strong> and <strong>1 UX/UI designer</strong> to uncover the root of the problems that occurred and discuss the tech constraint as well as the possible solutions. Discussed topics were about the daily routine, current workflow, design issues, and tools preference.</p>

            <h3>Insights</h3>
            <ul>
              <li>No one mentioning or sounding whenever designer or engineer make an update.</li>
              <li>Untracked design status due to there is no convention about how we should document the design request, temporary design/solution logging, and design implementation status.</li>
              <li>There was no guidelines about how a layout/element should be handled in certain conditions (e.g. when the spacing should be fixed or fluid). For the most part just need a simple do’s and don’ts.</li>
              <li>Scattered assets delivery (Icon and illustration) as well as copywriting.</li>
              <li>Due to lack of product owner people, we have to create and manage features of certain products independently.</li>
              <li>Designers and engineers are not using same terms that sometimes cause misunderstanding (e.g color naming and typography scale naming).</li>
              <li>No one in charge to monitor the design status (both designer and engineer side).</li>
            </ul>

            <h2>Challenges</h2>
            <h3>Getting everyone onboard</h3>
            <p>A Design System is not a one-man project and if you want it to succeed you need everyone on board. The more integrated you want it the more disciplines you need at the table.</p>
            <h3>Planning & Priority</h3>
            <p>Failing to plan is planning to fail. We constantly toggle between working on our Design System and enhancing the application and planning and prioritizing our tasks is imperative.</p>

            <h2>The Process</h2>
            <h3>Laying The Foundation</h3>
            <p>Luckily, my fellow UI/UX designer already made some core/foundation components (using atomic design approach) that make me easier to contribute to the design system. However, merging multiple design languages from different products still tricky and painful works.</p>

            <h4>Grid</h4>
            <p>We use the 8pt grid system to space out the elements on a page. This means that any defined hight or width , margin or padding will be increment of 8. However, we still have some exceptions for certain needs.</p>

            <h4>Color</h4>
            <p>The dark mode approach used due to our brand identity is basically dark and yellow. Furthermore, the dark concept helps reduce eye strain while using some of Volantis’s condensed UI. Not to mention, it also helps the user to focus on content in our graphic-heavy such as data visualization app (Xplorer).</p>

            <p>We use gray shade extensively for your container color needs. For primary color, we use Yellow #FFD77B (brand color). Various color accents needed to accommodate data visualization needs (think chart color).</p>
          </ContentCopy>

          <ContentImageM>
            <Img fluid={data.imageFive.childImageSharp.fluid} alt="color palette" />
          </ContentImageM>

          <ContentCopy>
            <h4>Type Face</h4>
            <p>Roboto as the base Typeface across products, Roboto Condensed & Sarabun for landing pages, and Inconsolata for code snippet needs.</p>
          </ContentCopy>

          <ContentImageM>
            <Img fluid={data.imageSix.childImageSharp.fluid} alt="roboto" />
            <Img fluid={data.imageSeven.childImageSharp.fluid} alt="sarabun" />
            <Img fluid={data.imageEight.childImageSharp.fluid} alt="roboto condensed" />
            <Img fluid={data.imageNine.childImageSharp.fluid} alt="inconsolata" />
          </ContentImageM>

          <ContentCopy>
            <h4>Icon</h4>
            <p>We use outline style with some exceptions to complicated forms that still need filled style. Our main Icon sizes are ranging from 18px, 24px, and 32px. There are some exceptions for special needs we use the smallest one which is 12px and the biggest one 36px.</p><br />
            <p>Basically, we follow the Material Design icons principle with some exceptions in the name of optical alignment or certain special needs.</p><br />
          </ContentCopy>
          <ContentImageL>
            <Img fluid={data.imageTen.childImageSharp.fluid} alt="icon symbols" />
          </ContentImageL>
          <ContentCopy>
            <h4>Button</h4>
            <p>Since we use buttons heavily in our UI we divided buttons into these categories:</p>
            <ul>
              <li>Choice button</li>
              <li>Transparent button</li>
              <li>Contained button</li>
              <li>Icon with Dropdown</li>
              <li>Icon button</li>
              <li>Outlined button</li>
              <li>Toggle</li>
              <li>Toolbar button</li>
              <li>Toolbar with Dropdown</li>
            </ul>
            <br />
          </ContentCopy>
          <ContentImageL>
            <Img fluid={data.imageEleven.childImageSharp.fluid} alt="button symbols" />
          </ContentImageL>
          <ContentCopy>
            <h4>Input</h4>
            <p>Using same input elements and components provide unified language and consistent look throughout products.</p><br />
          </ContentCopy>
          <ContentImageL>
            <Img fluid={data.imageTwelve.childImageSharp.fluid} alt="input symbols" />
          </ContentImageL>
          <ContentCopy>
            <h2>Bringing it all together</h2>
            <h3>Monitor Requests and Implenetations</h3>
            <p>In order to solve communication issues between Designers and Engineers I set up a Trello kanban to manage Icon and Component requests. Starting from request until implementation monitoring in production.</p><br />
            <p>UI Engineer taking the charge as PIC to monitor and remind everyone to follow the procedure.</p>
          </ContentCopy>
          <ContentImageL>
            <Img fluid={data.imageThirteen.childImageSharp.fluid} alt="trello setup" />
          </ContentImageL>
          <ContentCopy>
            <h3>Conventions</h3>
            <p>Make agreements about naming colors, typography scale, and components (both in sketch and code form).</p>
            <h3>Single source of truth (Icons & Illustrations)</h3>
            <p>Store icons and illustrations in one repo (landing page design system) including version log and code snippet.</p>
            <h3>Best practice and exceptions</h3>
            <p>Devine do's and don'ts for commonly misused or ambiguous components.</p>

            <h2>Impact</h2>
            <p>After a couple weeks testing out the new workflow, we found some interesting results:</p>
            <ol>
              <li>No more breaking change whenever new design applied.</li>
              <li>No more random blaming.</li>
              <li>Able to spot someone who messes the workflow.</li>
              <li>Foster the sense of belonging.</li>
              <li>Better overall collaboration between designers and engineers.</li>
              <li>Bring everyone on the same page.</li>
            </ol>
            <br />
            <p>Afterwards, New landing concept was tested and had to be redesigned. Basically, it will be separated into 3 main segments which are marketing pages (logo, brand identity, etc), design pages (guidelines, files, etc), and component pages (ReactJS docs/snippet for engineers).</p>

            <h2>Project Learnings</h2>
            <h4>Collaboration is key</h4>
            <p>The more eyes on a design, the more it’s exposed to varying opinions, experience, and critique — and this can only ultimately improve it. Or at the very least, test it.</p>
            <h4>Documentation in essential</h4>
            <p>No matter how rushed a project is, every decision and temporary solutions must be documented and announced trough the entire team.</p>
            <h4>Don’t be limited by existing blocks</h4>
            <p>Don’t force existing components to make an interface that ugly or has bad usability just for the shake of consistency. It is part of the design system development to add more components whenever it is needed.</p>
            <h4>Get the buy-in</h4>
            <p>Since this project is volunteer works, everything is often gets slowed down and postponed. Sometimes we have to block the schedule for example, ask for half day on Friday to evaluate design system would make things more manageable.</p>
          </ContentCopy>
        </div>
      </div>
      <div className="column is-12">
        <hr />
      </div>

      {/* More Cases Section  */}
      <div className="column is-10">
        <div className="columns is-centered is-multiline">
          <div className="column is-12">
            <h3 className="has-text-centered margin-top-0">More Case Studies</h3>
          </div>
          <div className="column is-4 is-flex">
            <div className="box">
              <div className="columns is-centered">
                <div className="column is-12">
                  <Link to="/case-studies/gredu-design-system-v0.1">
                    <Img fluid={data.imageFour.childImageSharp.fluid} alt="featured image" />
                  </Link>
                  <div className="post-card">
                    <Link to="/case-studies/gredu-design-system-v0.1"><h2 className="read-more-title">Gredu Design System V.01</h2></Link>
                    <Link to="/case-studies/gredu-design-system-v0.1"><p className="read-more-excerp">A design system starter that will cover more than 5 products in the school ecosystem (web, iOS, & Android).</p></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-4 is-flex">
            <div className="box">
              <div className="columns is-centered">
                <div className="column is-12">
                  <Link to="/case-studies/hris-markplus">
                    <Img fluid={data.imageThree.childImageSharp.fluid} alt="featured image" />
                  </Link>
                  <div className="post-card">
                    <Link to="/case-studies/hris-markplus"><h2 className="read-more-title">HRIS MarkPlus, Inc.</h2></Link>
                    <Link to="/case-studies/hris-markplus"><p>Human resource information system that covers attendance, leave, benefits, employees information, etc.</p></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-4 is-flex">
            <div className="box">
              <div className="columns is-centered">
                <div className="column is-12">
                  <Link to="/case-studies/student-app-course">
                    <Img fluid={data.imageFourteen.childImageSharp.fluid} alt="featured image" />
                  </Link>
                  <div className="post-card">
                    <Link to="/case-studies/student-app-course"><h2 className="read-more-title">Student App - Course</h2></Link>
                    <Link to="/case-studies/student-app-course"><p>Adding a course platform with a subscription model into an existing student app.</p></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  </Layout >
);

export default VolantisDS;


export const query = graphql`
  query VolantisDSQuery {
    imageOne:file(relativePath: { eq: "volantis-ds-hero.png" }) {
      childImageSharp {
        fluid(maxWidth:2000, quality: 90){
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo:file(relativePath: { eq: "volantis-ds-products.png" }) {
        childImageSharp {
          fluid(maxWidth:2000, quality:80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageThree:file(relativePath: { eq: "hris-markplus.png" }) {
        childImageSharp {
          fluid(quality:80){
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFour:file(relativePath: { eq: "gredu-ds-page.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFive:file(relativePath: { eq: "volantis-ds-color.png" }) {
        childImageSharp {
          fluid(quality:80){
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageSix:file(relativePath: { eq: "volantis-ds-roboto.png" }) {
        childImageSharp {
          fluid(maxWidth:2000){
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageSeven:file(relativePath: { eq: "volantis-ds-sarabun.png" }) {
        childImageSharp {
          fluid(maxWidth:2000){
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageEight:file(relativePath: { eq: "volantis-ds-roboto-condensed.png" }) {
        childImageSharp {
          fluid(maxWidth:2000){
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageNine:file(relativePath: { eq: "volantis-ds-inconsolata.png" }) {
        childImageSharp {
          fluid(maxWidth:2000){
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTen:file(relativePath: { eq: "volantis-ds-icon-symbols.png" }) {
        childImageSharp {
          fluid(quality: 80){
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageEleven:file(relativePath: { eq: "volantis-ds-buttons.png" }) {
        childImageSharp {
          fluid(maxWidth:2000){
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTwelve:file(relativePath: { eq: "volantis-ds-text-input.png" }) {
        childImageSharp {
          fluid(maxWidth:2000){
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageThirteen:file(relativePath: { eq: "volantis-ds-trello.png" }) {
        childImageSharp {
          fluid(maxWidth:2000){
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFourteen:file(relativePath: { eq: "student-app-course-page.png" }) {
        childImageSharp {
          fluid(maxWidth:2000){
            ...GatsbyImageSharpFluid
          }
        }
      }

  }
`
